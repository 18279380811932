body, html {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    height: 100%;
    font-size: 16px;
    background: linear-gradient(125deg, #000000 63%, #bf6df9, #e302b3);
    color: white;
    overflow-x: hidden;
}

.App {
    text-align: center;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.home-page-wrapper {
    min-height: 100vh;
    position: relative;
    background-color: #ffffff00;
    overflow: hidden;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

.home-page {
    position: relative;
    color: #000000;
    z-index: 1;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    font-family: inherit;
}

.hero {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
    padding: 0 20px;
    margin-bottom: 0;
    font-family: inherit;
}

.content {
    z-index: 1;
    max-width: 800px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: inherit;
}

h1 {
    font-size: 56px;
    margin-bottom: 20px;
    color: #fff;
    font-family: inherit;
}

.hero p {
    font-size: 20px;
    margin-bottom: 40px;
    color: #ffffff;
    font-family: inherit;
}

.button-group {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 20px;
}

.cta-button {
    background-color: transparent;
    color: #FFFFFF;
    padding: 15px 30px;
    border: 1px solid #FFFFFF;
    border-radius: 0;
    text-decoration: none;
    font-weight: 500;
    font-size: 18px;
    transition: background-color 0.3s ease, color 0.3s ease;
    display: inline-block;
    width: 150px;
    text-align: center;
}

.cta-button:hover {
    background-color: rgba(255, 255, 255, 0.1);
    color: #FFFFFF;
}

.meet-button {
    display: block;
    margin: 0 auto;
}

.prosthetic-arm-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.prosthetic-arm {
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    transform-origin: center;
    transition: transform 0.3s ease;
    opacity: 0.4;
}

.info-section {
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: #00000000;
    margin-top: 0;
    flex: 1;
}

.info-subsection {
    margin: 20px 0;
    padding: 20px;
    background-color: #ffffff00;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
}

.info-subsection h2 {
    font-size: 24px;
    margin-bottom: 10px;
    color: #ffffff;
}

.info-subsection p {
    font-size: 16px;
    color: #ffffff;
}

.additional-content {
    display: block;
    opacity: 1;
    font-size: 16px;
    color: #333;
    line-height: 1.6;
    text-align: left;
    max-width: 600px;
    margin: 0 auto;
    list-style-type: disc;
    padding-left: 0;
}

.additional-content li {
    margin-bottom: 15px;
    text-align: left;
}

.team-members {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    margin-top: 20px;
    flex-wrap: wrap;
    gap: 10px;
}

.team-member {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;
    flex: 1;
    min-width: 150px;
    max-width: 200px;
    transition: all 0.3s ease;
    background-color: transparent;
    color: #FFFFFF;
    position: relative;
    overflow: hidden;
}

.team-member:hover {
    background-color: #FFFFFF;
    color: #c964df;
    max-width: 600px;
    padding: 20px;
    z-index: 1;
}

.team-member h3, .team-member p {
    transition: color 0.3s ease;
}

.team-member:hover h3, .team-member:hover p {
    color: #c964df;
}

.member-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 10px;
}

.member-info {
    text-align: center;
}

.member-info h3{
    color: #fff
}

.member-bio {
    font-size: 14px;
    color: #666;
    transition: opacity 0.3s ease;
}

.team-member:hover .member-bio {
    display: none;
}

.long-bio {
    display: none;
    font-size: 14px;
    color: #c964df;
    margin-top: 10px;
}

.team-member:hover .long-bio {
    display: block;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: #000000;
    z-index: 1001;
    padding: 5px 10px;
    transition: color 0.3s ease;
}

.close-button:hover {
    color: #c964df;
}

.footer {
    background-color: #f5f5f500;
    text-align: center;
    padding: 10px 0;
    color: #666;
    font-size: 14px;
    border-top: 1px solid #ddd;
}

.footer-links {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 10px;
}

.footer-link {
    color: #FFFFFF;
    text-decoration: none;
    font-size: 16px;
    transition: color 0.3s ease;
}

.footer-link:hover {
    color: #c964df;
}

.footer a[href*="linkedin"] {
    color: #c964df; /* Pink color for LinkedIn link */
}

@media (max-width: 768px) {
    .info-section {
        flex-direction: column;
    }

    .info-subsection {
        margin: 10px 0;
    }

    .team-members {
        justify-content: center;
    }
}
.mission-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 20px;
    margin-top: 20px;
}

.mission-box {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
}

.mission-box:nth-child(1) {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
}

.mission-box:nth-child(2) {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
}

.mission-box:nth-child(3) {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
}

.mission-box:nth-child(4) {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
}

.mission-box h3 {
    font-size: 20px;
    margin-bottom: 10px;
    color: #FFFFFF;
    text-align: center;
    width: 100%;
}

.mission-box p {
    font-size: 16px;
    color: #FFFFFF;
    text-align: center;
    width: 100%;
}

.logo img {
    height: 13rem;
    width: auto;
}

@media (max-width: 768px) {
    .mission-content {
        grid-template-columns: 1fr;
        grid-template-rows: auto;
    }

    .mission-box {
        grid-column: span 1;
    }
}

@media (max-width: 768px) {
    .logo img {
      height: 8rem;
    }

    .hero p {
        font-size: 14px;
        margin-top: 10px;
        white-space: normal;
        word-wrap: break-word;
    }
}